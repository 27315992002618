import React from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'

// Component imports
import SadlerHeader from '../../component/shared/sadler_header/SadlerHeader'

// Hook Imports
import { useCompany } from '../../hooks/useCompany'

// Third-party Imports
import { useNavigate, useSearchParams } from 'react-router-dom'

function LandingPage() {

    const navigate = useNavigate()

    // Hooks
    const { company, updateComapny } = useCompany()
    const [searchParams] = useSearchParams();

    const handleClickNewCompany = () => {
        let company = { request_type: 'New company' }
        if (searchParams.get("referralid")) {
            company.referral_id = searchParams.get("referralid")
            company.referral_id_readonly = true
        }
        searchParams.get("referralid")
        updateComapny(company)
        navigate('/company-form')
    }

    const handleClickExistingCompany = () => {
        let company = { request_type: 'Switching company' }
        if (searchParams.get("referralid")) {
            company.referral_id = searchParams.get("referralid")
            company.referral_id_readonly = true
        }
        updateComapny(company)
        navigate('/company-form')
    }

    return (
        <div className='px-8'>
            <Grid container justifyContent="center">
                <Grid item sm={12} md={6}>
                    <div className='mt-14 px-4 text-center mb-12'>
                        <SadlerHeader />
                    </div>
                </Grid>
                <Grid item sm={12} md={8}>
                    <Grid container justifyContent="center" spacing={8} className='!mt-8'>
                        <Grid item xs={12} sm={6} lg={5} xl={4}>
                            <Card className='!cursor-pointer !transition !duration-300 hover:opacity-85'>
                                <CardContent className='flex flex-col items-center text-center h-[400px]'>
                                    <div className='h-full flex flex-col justify-between items-center'>
                                        <div className='flex flex-col items-center'>
                                            <img className='w-32 !bg-gray-300 mb-2' src='/new_company_image.jpg' alt='new_company_image' />
                                            <Typography variant='h5' className='!font-bold '>
                                                Starting a new company
                                            </Typography>
                                            <Typography color='text.secondary' className='!my-4'>
                                                Launch your new business effortlessly with Sadler & Frost. We'll handle registration and paperwork, ensuring a smooth start.
                                            </Typography>
                                        </div>
                                        <Button variant='contained' onClick={handleClickNewCompany}>Start Now</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={5} xl={4}>
                            <Card className='!cursor-pointer !transition !duration-300 hover:opacity-85'>
                                <CardContent className='flex flex-col items-center text-center h-[400px]'>
                                    <div className='h-full flex flex-col justify-between items-center'>
                                        <div className='flex flex-col items-center'>
                                            <img className='w-32 !bg-gray-300 mb-2' src='switching_company_image.jpg' alt='switching_company_image' />
                                            <Typography variant='h5' className='!font-bold'>
                                                Transfer your existing company
                                            </Typography>
                                            <Typography color='text.secondary' className='!my-4'>
                                                Switch to Sadler & Frost for hassle-free accountancy services. We’ll manage all aspects of the transition, ensuring uninterrupted financial operations.
                                            </Typography>
                                        </div>
                                        <Button variant='contained' onClick={handleClickExistingCompany}>Start Now</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default LandingPage