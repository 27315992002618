import { useState, useEffect } from 'react'

// MUI Imports
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import FormHelperText from '@mui/material/FormHelperText'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse';

// Third-party Imports
import { Controller, useForm } from 'react-hook-form'
import { valibotResolver } from '@hookform/resolvers/valibot'
import { Icon } from '@iconify/react/dist/iconify.js'
import * as v from 'valibot';

// Hook Imports
import { useCompany } from '../../../hooks/useCompany'

// Component imports
import DirectionalIcon from '../../../component/icon/DirectionalIcon'
import CustomTextField from '../../../component/custom_text_field/CustomTextField'

const step3Schema = v.pipe(
    v.object({
        interested_service: v.pipe(
            v.object({
                accounting_and_bookkeeping: v.pipe(v.boolean(), v.check((value) => value)),
                vat_returns: v.boolean(),
                payroll: v.boolean(),
                pension: v.boolean(),
                ecommerce_accounting: v.boolean(),
                other: v.boolean(),
            }),
        ),
        accounting_option: v.pipe(
            v.string()
        ),
        payroll_option: v.pipe(
            v.string()
        ),
        ecommerce_option: v.pipe(
            v.string()
        ),
        other_interested: v.pipe(
            v.string(),
            v.trim()
        )
    }),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['accounting'],
            ],
            (input) => {
                if (input.interested_service.accounting_and_bookkeeping) {
                    if (input.accounting_option) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'accounting_option',
        ]
    ),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['payroll_option'],
            ],
            (input) => {
                if (input.interested_service.payroll) {
                    if (input.payroll_option) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'payroll_option',
        ]
    ),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['ecommerce_option'],
            ],
            (input) => {
                if (input.interested_service.ecommerce_accounting) {
                    if (input.ecommerce_option) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'ecommerce_option',
        ]
    ),
    v.forward(
        v.partialCheck(
            [
                ['interested_service'],
                ['other_interested'],
            ],
            (input) => {
                if (input.interested_service.other) {
                    if (!input.other_interested) {
                        return false
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            },
            'The two passwords do not match.',
        ),
        [
            'other_interested',
        ]
    ),
)

function LimitedStep3({ onSubmit, handleBack, title }) {
    // State
    const [showAccountingTransactions, setShowAccountingTransactions] = useState(false)
    const [showPayrollHowManyEmployee, setShowPayrollHowManyEmployee] = useState(false)
    const [eCommerceOption, setECommerceOption] = useState(false)
    const [showOther, setShowOther] = useState(false)
    // Hooks
    const { company } = useCompany()

    const {
        control: step3Control,
        handleSubmit: handleStep3Submit,
        formState: { errors: step3Errors },
        watch
    } = useForm({
        resolver: valibotResolver(step3Schema),
        defaultValues: {
            interested_service: company.interested_service,
            accounting_option: company.accounting_option,
            payroll_option: company.payroll_option,
            ecommerce_option: company.ecommerce_option,
            other_interested: company.other_interested
        }
    })

    useEffect(() => {
        if (
            watch('interested_service.accounting_and_bookkeeping')
        ) {
            setShowAccountingTransactions(true)
        } else {
            setShowAccountingTransactions(false)
        }

    }, [watch('interested_service.accounting_and_bookkeeping')])


    useEffect(() => {
        if (
            watch('interested_service.payroll')
        ) {
            setShowPayrollHowManyEmployee(true)
        } else {
            setShowPayrollHowManyEmployee(false)
        }

    }, [watch('interested_service.payroll')])

    useEffect(() => {
        if (
            watch('interested_service.ecommerce_accounting')
        ) {
            setECommerceOption(true)
        } else {
            setECommerceOption(false)
        }

    }, [watch('interested_service.ecommerce_accounting')])

    useEffect(() => {
        if (
            watch('interested_service.other')
        ) {
            setShowOther(true)
        } else {
            setShowOther(false)
        }

    }, [watch('interested_service.other')])


    return (
        <form key={0} onSubmit={handleStep3Submit(onSubmit)}>
            <Grid container spacing={6}>
                <Grid item xs={12} >
                    <Typography variant='h5' className='!text-textPrimary !font-bold'>{title}</Typography>
                    <div className='flex flex-col mt-2'>
                        <Controller
                            name='interested_service.accounting_and_bookkeeping'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>Limited Company Accounting & Bookkeeping</span>} />
                                    {step3Errors.interested_service?.accounting_and_bookkeeping && <FormHelperText error>This field is required.</FormHelperText>}
                                    <Collapse in={showAccountingTransactions}>
                                        <div className='ml-8'>
                                            <Controller
                                                name='accounting_option'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <RadioGroup {...field} name='radio-buttons-group' className='mt-2'>
                                                        <FormControlLabel value='Up to 40' control={<Radio />} label='Up to 40 transactions per month' />
                                                        <FormControlLabel value='40-120' control={<Radio />} label='40 to 120 transactions per month' />
                                                        <FormControlLabel value='120+' control={<Radio />} label='120+ transactions per month' />
                                                        <FormControlLabel value='No Bookkeeping' control={<Radio />} label='Only Year-End and Corporation Tax Submission (No Bookkeeping)' />

                                                    </RadioGroup>
                                                )}
                                            />
                                            {step3Errors.accounting_option && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </div>
                                    </Collapse>
                                </div>

                            )}
                        />
                        <Controller
                            name='interested_service.vat_returns'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>VAT Returns</span>} />
                                </div>

                            )}
                        />
                        <Controller
                            name='interested_service.payroll'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>Payroll</span>} />
                                    <div className='ml-8'>
                                        <Collapse in={showPayrollHowManyEmployee}>
                                            <Controller
                                                name='payroll_option'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <div>
                                                        <Typography variant='h6' className='!font-bold'>How many payrolls do you need including employees and Company Director(s)?</Typography>
                                                        <CustomTextField
                                                            {...field}
                                                            fullWidth
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: { min: 0 }
                                                            }}
                                                        // label='How many payrolls do you need including employees and Company Director(s)?'
                                                        />
                                                    </div>
                                                )}
                                            />
                                            {step3Errors.payroll_option && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </Collapse>
                                    </div>
                                </div>
                            )}
                        />
                        <Controller
                            name='interested_service.pension'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>Pension</span>} />
                                </div>
                            )}
                        />
                        <Controller
                            name='interested_service.ecommerce_accounting'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>E-commerce Accounting</span>} />
                                    <Collapse in={eCommerceOption}>
                                        <div className='ml-8'>
                                            <Controller
                                                name='ecommerce_option'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <RadioGroup {...field} name='radio-buttons-group' className='mt-2'>
                                                        <FormControlLabel value='Up to 200' control={<Radio />} label='Up to 200 ecommerce transactions per month' />
                                                        <FormControlLabel value='Up to 1000' control={<Radio />} label='Up to 1000 ecommerce transactions per month' />
                                                        <FormControlLabel value='Up to 5000' control={<Radio />} label='Up to 5000 ecommerce transactions per month' />
                                                    </RadioGroup>
                                                )}
                                            />
                                            {step3Errors.ecommerce_option && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </div>
                                    </Collapse>
                                </div>

                            )}
                        />
                        <Controller
                            name='interested_service.other'
                            control={step3Control}
                            render={({ field }) => (
                                <div>
                                    <FormControlLabel control={<Checkbox {...field} checked={field.value} />} label={<span className='font-bold'>Other Services? Please specify.</span>} />
                                    <div className='ml-8'>
                                        <Collapse in={showOther}>
                                            <Controller
                                                name='other_interested'
                                                control={step3Control}
                                                rules={{ required: true }}
                                                render={({ field }) => (
                                                    <CustomTextField
                                                        {...field}
                                                        variant='text'
                                                        fullWidth
                                                        multiline
                                                        rows={4}
                                                    />
                                                )}
                                            />
                                            {step3Errors.other_interested && <FormHelperText error>Please fill out this field.</FormHelperText>}
                                        </Collapse>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} className='flex justify-between'>
                    <Button
                        variant='tonal'
                        onClick={handleBack}
                        color='secondary'
                        startIcon={<DirectionalIcon ltrIcon='tabler-arrow-left' rtlIcon='tabler-arrow-right' />}
                    >
                        Back
                    </Button>
                    <Button variant='contained' type='submit' endIcon={<Icon icon='tabler-check' />}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default LimitedStep3