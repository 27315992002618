// React Imports
import { useEffect } from 'react'

// MUI Imports
import { useColorScheme } from '@mui/material/styles'

// Third-party Imports
import { useMedia } from 'react-use'

// Hook Imports
import { useSettings } from '../../hooks/useSetting'

const ModeChanger = () => {
    // Hooks
    const { setMode } = useColorScheme()
    const { settings, updateSettings } = useSettings()
    const isDark = useMedia('(prefers-color-scheme: dark)');

    useEffect(() => {
        if (settings.mode) {
            if (settings.mode === 'system') {
                setMode(isDark ? 'dark' : 'light')
            } else {
                setMode(settings.mode)
            }
            updateSettings({ systemMode: isDark ? 'dark' : 'light' })
        }
    }, [settings.mode, isDark]);

    return null
}

export default ModeChanger
