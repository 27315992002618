// MUI Imports
import { useTheme } from '@mui/material/styles'

// Third-party Imports
import classnames from 'classnames'
import { Icon } from '@iconify/react/dist/iconify.js'

const DirectionalIcon = props => {
  // Props
  const { ltrIcon, rtlIcon, className } = props

  // Hooks
  const theme = useTheme()

  return (
    <Icon className={className} icon={theme.direction === 'ltr' ? ltrIcon : rtlIcon} />
  )
}

export default DirectionalIcon
